import React, {
  Suspense,
} from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { appsignal } from './appsignal/appsignal';
import Routes from './Routes';

import 'react-virtualized/styles.css';
import '@myjobglasses/admin-ui/dist/index.css';

const SchoolRouter = React.lazy(() => import('./scenes/schools/SchoolRouter'));
function App() {
  return (
    <ErrorBoundary
      fallback={<></>}
      onError={(error, { componentStack }) => {
        appsignal.sendError(error, (span) => {
          const formattedError: Error = {
            name: `${error.name} - ${error.message}`,
            message: error.message,
            stack: componentStack,
          };
          span.setError(formattedError);
          span.setNamespace('InAppErrorBoundary');
        });
      }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Switch>
            <Route
              path={Routes.schoolAdmin}
              component={SchoolRouter}
            />
            <Redirect
              to={Routes.schoolAdmin}
            />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
