import Appsignal from '@appsignal/javascript';
import { plugin as pluginpathdecorator } from '@appsignal/plugin-path-decorator';

export const appsignal = new Appsignal({
  key: process.env.REACT_APP_APPSIGNAL_VOCATION_FRONTEND_MONITORING_KEY,
  revision: process.env.REACT_APP_FRIENDLY_TAG || '',
});

appsignal.use(pluginpathdecorator());

export const sendError = (message: string, error?: any, options?: {[key: string]: any}) => {
  appsignal.sendError(
    error || new Error(message),
    (span: any) => {
      span.setAction(message);
      if (options) { span.setTags(options); }
    },
  );
};
